const theme = {
  font: {
    logo: `'Novecento WideBold'`,
    primary: `'Montserrat', serif`,
    secondary: `'Montserrat', serif`,
  },
  font_size: {
    xsmall: 'font-size: 18px; line-height: 26px',
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 22px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 40px; line-height: 58px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    primary: '#EAB83E',
    secondary: '#633520',
    text: '#000'
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
